<template>
  <VList
    :rows="rows"
    :headers="headers"
    display-pagination
    :is-loading="isLoading"
    :pagination="pagination"
    @click:training="onClickRedirectToTraining"
    @click:learner="onClickRedirectToLearner"
    @update:current_page="onUpdateCurrentPage"
    @update:per_page="onUpdatePerPage"
  >
    <template #title>
      {{ $t("app.trainings", 2) }}
    </template>

    <template #title-actions>
      <VDropdown
        dropdown-menu-class="w-44"
        :selected="selectedOption"
        :options="options"
        @click="selectedOption = $event"
      >
        <template #option="{ option }">
          <div class="cursor-pointer p-2 truncate">
            {{ option.text }}
          </div>
        </template>
      </VDropdown>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage
        :src="item.displayed_avatar"
        :color="item.colour"
        :name="item.displayed_name"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.learner="{ item }">
      <div>
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_learner }}
        </div>

        <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
          @{{ item.displayed_username }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.training="{ item }">
      <div>
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_training }}
        </div>

        <div class="version mt-0.5">
          {{ $t("app.versions") }} {{ item.displayed_training_version }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { onMounted, watch, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
import usePaginate from "@/composables/usePaginate";
import useReadMultiple from "@/composables/useReadMultiple";
import useTexts from "@/composables/useTexts";
// Components
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";
import VList from "@/components/tables/VList";
import VDropdown from "@/components/VDropdown";

export default {
  components: {
    VChip,
    VList,
    VImage,
    VDropdown
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const options = [
      {
        text: t("app.INSTRUCTOR"),
        value: "INSTRUCTOR"
      },
      {
        text: t("app.REGISTRATION_APPROVER"),
        value: "REGISTRATION_APPROVER"
      },
      {
        text: t("app.COMPLETION_APPROVER"),
        value: "COMPLETION_APPROVER"
      }
    ];

    // Data
    const selectedOption = ref(options[0].value);

    // Composables
    const { data, isLoading, read, pagination } = useReadMultiple();
    const { getStatusColor } = useColor();
    const { getAvatarURL } = useDisplay();
    const { getText } = useTexts();

    // Computed
    const rows = computed(() => {
      if (hasRegistrationData.value) {
        return data.value?.map(row => ({
          ...row,
          learner_id: row?.learner?.id,
          training_id: row?.training?.id,
          displayed_name: `${row?.learner?.firstname} ${row?.learner?.lastname}`,
          // eslint-disable-next-line
          displayed_learner: `${row?.learner?.firstname} ${row?.learner?.lastname}`,
          displayed_username: row?.learner?.username,
          displayed_avatar: getAvatarURL(row?.learner?.avatar?.view_path),
          displayed_training: getText(row?.training?.texts, "name"),
          displayed_training_version: row?.training?.version,
          displayed_status: row.status ? t(`app.${row.status}`) : ""
        }));
      }

      return data.value?.map(row => ({
        ...row,
        training_id: row?.id,
        displayed_name: getText(row?.texts, "name"),
        displayed_training: getText(row?.texts, "name"),
        displayed_training_version: row?.version,
        displayed_status: row.status ? t(`app.${row.status}`) : ""
      }));
    });
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.trainings", 2)} - ${t("app.instructors", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });
    const hasRegistrationData = computed(() => {
      const registrationOptions = [
        "REGISTRATION_APPROVER",
        "COMPLETION_APPROVER"
      ];

      return registrationOptions.includes(selectedOption.value);
    });
    const headers = computed(() => {
      if (hasRegistrationData.value) {
        return [
          {
            value: "image",
            class: "w-10"
          },
          {
            text: t("app.learners"),
            value: "learner",
            cellClass: "cursor-pointer"
          },
          {
            text: t("app.trainings"),
            value: "training",
            cellClass: "cursor-pointer"
          },
          {
            text: t("app.status"),
            value: "status",
            class: "w-40"
          }
        ];
      }

      return [
        {
          value: "image",
          class: "w-10"
        },
        {
          text: t("app.trainings"),
          value: "training",
          cellClass: "cursor-pointer"
        },
        {
          text: t("app.status"),
          value: "status",
          class: "w-40"
        }
      ];
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.instructors.trainings",
        pathParams: {
          id: props.id,
          type: selectedOption.value
        },
        showToaster: false
      });
    };

    const onClickRedirectToTraining = row => {
      const id = row?.training_id;

      router.push({
        name: "teaching-trainings-overview",
        params: { id }
      });
    };

    const onClickRedirectToLearner = row => {
      const id = row?.learner_id;

      router.push({
        name: "teaching-learners-overview",
        params: { id }
      });
    };

    // eslint-disable-next-line
    const { onUpdatePerPage, onUpdateCurrentPage } = usePaginate(pagination, getData);

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    // Watch
    watch(selectedOption, async () => {
      await getData();
    });

    return {
      options,
      selectedOption,
      documentTitle,
      rows,
      headers,
      onClickRedirectToTraining,
      onClickRedirectToLearner,
      // useReadMultiple
      data,
      isLoading,
      pagination,
      // usePaginate
      onUpdatePerPage,
      onUpdateCurrentPage,
      // useColor
      getStatusColor
    };
  }
};
</script>
